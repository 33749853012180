// import libraries
import React, { useEffect, useState } from "react";

// import icons
import Checkmark from "../../assets/images/check-mark.svg";

// import CSS file for styling
import "./pricing.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import authDataObject from "../../services/Auth_Controller/Auth_Controller";
import { Spinner } from "reactstrap";
import ConfirmationAlertModal from "../ProfileModal/components/ConfirmationAlertModal";
import SubscriptionConfirm from "../Home/SubscriptionConfirm";

const PricingComponent = () => {
  const [subscriptionData, setSubscriptionData] = React.useState([]);

  const { data: listData, isFetching: isFetching1 } = useQuery({
    queryKey: [`subscription-list`],
    queryFn: () => authDataObject.getSubscriptionList(),

    enabled: localStorage.getItem("token") ? false : true,
  });

  const {
    data: listDataForUser,
    isFetching: isFetching2,
    refetch,
  } = useQuery({
    queryKey: [`subscription-list-for-user`],
    queryFn: () => authDataObject.getSubscriptions(),
    enabled: localStorage.getItem("token") ? true : false,
  });

  useEffect(() => {
    if (listData) {
      setSubscriptionData(listData);
    }
  }, [listData]);

  useEffect(() => {
    if (listDataForUser) {
      setSubscriptionData(listDataForUser);
    }
  }, [listDataForUser]);

  return (
    <div className="FWH Cl">
      {isFetching1 || isFetching2 ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: "1050",
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(1.5px)",
          }}
        >
          <Spinner
            size={"xl"}
            className="m-1 spinner-loader"
            color="secondary"
            style={{
              width: "50px",
              height: "50px",
              zIndex: "102",
            }}
          />{" "}
          <span
            style={{
              color: "#6c757d",
            }}
          >
            Please wait...
          </span>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "end",
          flexDirection: "row",
          width: "100%",
          gap: "20px",
          minHeight: "400px",
          padding: "5px 10px",
        }}
      >
        {subscriptionData?.data?.details?.map((data, index) => (
          <SubscriptionCard
            key={index}
            name={data.name}
            display_name={data.display_name}
            description={data.description}
            amount={data.amount}
            interval={data.interval}
            details={data.details}
            isSelected={data.is_subscribed}
            is_popular={data.is_popular}
            refetch={refetch}
            is_active={data.is_active}
            remaining_credits={data.remaining_credits}
          />
        ))}
      </div>
    </div>
  );
};

export default PricingComponent;

function SubscriptionCard({
  key,
  name,
  display_name,
  description,
  amount,
  interval,
  details,
  isSelected,
  is_popular,
  refetch,
  is_active,
  remaining_credits,
}) {
  const [paymentUpdateAlert, setPaymentUpdateAlert] = useState({
    show: false,
    message: "",
  });
  const queryClient = useQueryClient();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const { mutate, isLoading } = useMutation(authDataObject.updateSubscription, {
    onSuccess: () => {
      setShowConfirmation(false);
      // refetch();
      queryClient.invalidateQueries("subscription-list-for-user");
      queryClient.invalidateQueries("current-usage-nav-bar");
    },
    onError: (error) => {
      setPaymentUpdateAlert({
        show: true,
        message:
          error?.response?.data?.message ??
          "Something went wrong. Please try again later",
      });
    },
  });
  return (
    <div
      key={key}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div className="card-popular">{is_popular ? "Popular" : ""}</div>
      <div
        className="cardContainer borCol"
        style={{
          borderRadius: "10px",
          height: "500px",
          borderWidth: isSelected ? "2px" : "1px",
          borderColor: isSelected ? "blue" : "#B9B4B4",
        }}
      >
        <div>
          <div className="Cardsubcontainer">
            <span className="cardTitile">{display_name}</span>
            <div
              className="cardsubTitile"
              style={{
                marginTop: "3px",
              }}
            >
              {description}
            </div>
          </div>
        </div>
        <hr
          style={{
            margin: 0,
            padding: 0,
            backgroundColor: "#B9B4B4",
          }}
        />
        <div className="mtb10">
          <div className="Midit">
            {is_active ? (
              <>
                <div className="cardPrice">{`$${amount}`}</div>{" "}
                <div className="card-duration">{getPeriodText(interval)}</div>
              </>
            ) : (
              <div
                className="card-duration"
                style={{
                  padding: "10px 0px",
                }}
              >
                {getPeriodText(interval)}
              </div>
            )}
          </div>
          {is_active && (
            <span className="card-description">Billed {interval}</span>
          )}
          <div
            className="card-featureslist"
            style={{
              marginTop: "25px",
              marginBottom: "25px",
            }}
          >
            {details.length >= 1
              ? details.map((data, index) => (
                  <div className="card-feature" key={index}>
                    {" "}
                    <span className="cardfeature-icon">
                      <img src={Checkmark} alt="images" />
                    </span>
                    <div className="cardfeature-description">{data}</div>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div
          style={{
            marginTop: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {name === "lite" || name === "pro" ? (
            <button
              style={{
                marginBottom: !isSelected && "40px",
              }}
              disabled={isLoading}
              className="GostbtnSub"
              onClick={() => {
                localStorage.getItem("token")
                  ? setShowConfirmation(true)
                  : setPaymentUpdateAlert({
                      show: true,
                      message: "Please login to subscribe",
                    });
              }}
            >
              {isSelected ? "Re Subscribe" : `Choose ${name}`}
            </button>
          ) : is_active ? (
            <button
              style={{
                backgroundColor: isSelected && "#E9E1E1",
                color: isSelected && "#767171",
                cursor: isSelected && "default",
                marginBottom: "40px",
              }}
              disabled={isLoading || isSelected}
              className="GostbtnSub"
              onClick={() => {
                localStorage.getItem("token")
                  ? setShowConfirmation(true)
                  : setPaymentUpdateAlert({
                      show: true,
                      message: "Please login to subscribe",
                    });
              }}
            >
              {isSelected ? "Current Plan" : `Choose ${name}`}
            </button>
          ) : (
            <button
              style={{
                backgroundColor: isSelected && "#E9E1E1",
                color: isSelected && "#767171",
                cursor: isSelected && "default",
                marginBottom: !isSelected && "40px",
              }}
              disabled={isSelected || isLoading}
              className="GostbtnSub"
              onClick={() => {
                // redirect to contact sales page
                window.open("https://docudiveai.com/contact-us/", "_blank");
              }}
            >
              Contact sales
            </button>
          )}
        </div>
        {isSelected && (name === "lite" || name === "pro") && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginBottom: "10px",
              marginTop: "8px",
            }}
          >
            <span
              style={{
                color: "#6c757d",
                fontSize: "14px",
              }}
            >
              Remaining credits : {remaining_credits}
            </span>
          </div>
        )}
      </div>

      {showConfirmation && (
        <SubscriptionConfirm
          isOpen={showConfirmation}
          handleClose={() => {
            setShowConfirmation(false);
          }}
          description={`Are you sure you want to upgrade to ${name} plan?`}
          handleConfirm={() => {
            mutate(name);
          }}
          isLoading={isLoading}
        />
      )}
      {paymentUpdateAlert?.show && (
        <ConfirmationAlertModal
          isOpen={paymentUpdateAlert?.show}
          handleClose={() => {
            setPaymentUpdateAlert({
              show: false,
              message: "",
            });
          }}
          title={paymentUpdateAlert?.message}
        />
      )}
    </div>
  );
}

const getPeriodText = (interval) => {
  switch (interval) {
    case "monthly":
      return "Per month";
    case "yearly":
      return "Per year";
    case "weekly":
      return "Per week";
    case "daily":
      return "Per day";
    case "custom":
      return "Custom billing as per need";
    default:
      return "Pre month";
  }
};
