// import libraries
import React, { useState, useRef } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

// import icons
import { FaTimes } from "react-icons/fa";
import Download_icon from "../../../assets/images/export_icon.svg";
import Backicon from "../../../assets/images/backarrow.svg";

// import CSS file for styling
import "./settingmodal.css";

// import controller object for api calling
import authDataObject from "../../../services/Auth_Controller/Auth_Controller";
import { useQueryClient } from "react-query";
import ErrorAlert from "../ErrorAlert/ErrorAlert";

const Settingmodal = ({ isOpen, toggleModal, setIsFileUploading }) => {
  const [isTextPasteModalOpen, setIsTextPasteModalOpen] = useState(false);
  const [isLinkPasteModalOpen, setIsLinkPasteModalOpen] = useState(false);
  const [inputText, setInputText] = useState(""); // State to store the text input
  const [inputLink, setInputLink] = useState(""); // State to store the text input

  const fileInputRef = useRef(null);
  const queryClient = useQueryClient();

  const [showError, setShowError] = useState({
    isOpen: false,
    message: "",
  });

  const handleFileChange = async (event) => {
    const file = fileInputRef.current.files[0]; // Access the input's files property via ref
    if (!file) return;
    const fileName = file.name;
    if (fileName != "") {
      const body = {
        file_name: fileName,
      };
      setIsFileUploading(true);
      toggleModal();
      await authDataObject.AdminUploadPresigned(body, (result) => {
        if (result?.data.status == "success") {
          let reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = function (event) {
            const binaryFile = event.target.result;
            result.data.details.metadata.contentType = file.type;
            authDataObject.AdminUploadPresignedUrl(
              result.data?.details.pre_signed_url,
              binaryFile,
              result.data?.details.metadata,

              (result) => {
                if (result?.status == 200 || result?.status == "200") {
                  // setIsFileUploading(true);
                  queryClient.invalidateQueries("file-list");
                  queryClient.invalidateQueries([`current-usage-nav-bar`]);
                  // Close the current modal (Settingmodal)
                  // onStatusTrue(true); // Call the parent component's function with true as an argument
                } else {
                  if (result?.status == 403) {
                    setIsFileUploading(false);
                    toggleModal();
                    setShowError({
                      isOpen: true,
                      message: "Session expired",
                    });
                    // Close the current modal (Settingmodal)
                    // Redirect to the home page using the useHistory hook.
                    localStorage.clear();
                    // window.location.reload(true);
                  } else {
                    setIsFileUploading(false);
                    toggleModal(); // Close the current modal (Settingmodal)
                    setShowError({
                      isOpen: true,
                      message: "File upload failed. Please try again.",
                    });
                  }
                }
              }
            );
          };
        } else {
          if (result.status == 402) {
            setIsFileUploading(false);
            toggleModal(); // Close the current modal (Settingmodal)
            setShowError({
              isOpen: true,
              message: result.data.message,
            });
            return;
          }
          setIsFileUploading(false);
          toggleModal(); // Close the current modal (Settingmodal)
          setShowError({
            isOpen: true,
            message: "File upload failed. Please try again.",
          });
          console.log("here");
        }
      });
    }
  };

  const handlePasteLink = () => {
    setInputText("");
    setIsTextPasteModalOpen(true);
    toggleModal(); // Close the current modal (Settingmodal)
  };

  const handlePasteText = () => {
    const blob = new Blob([inputText], { type: "text/plain" });
    if (blob && inputText != "") {
      // Read the Blob as an ArrayBuffer
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      reader.onload = function (event) {
        const binaryFile = event.target.result;
        const body = {
          file_name: `${inputText?.substring(0, 20)}.txt`,
        };
        setIsFileUploading(true);
        setIsTextPasteModalOpen(false);
        authDataObject.AdminUploadPresigned(body, (result) => {
          if (result?.data.status == "success") {
            authDataObject.AdminUploadPresignedUrl(
              result?.data?.details?.pre_signed_url,
              binaryFile,
              result?.data?.details?.metadata,
              (result) => {
                if (result?.status == 200 || result?.status == "200") {
                  // setIsFileUploading(true);
                  queryClient.invalidateQueries("file-list");
                  // onStatusTrue(true); // Call the parent component's function with true as an argument
                } else {
                  if (result?.status == 403) {
                    setShowError({
                      isOpen: true,
                      message: "Session expired",
                    });
                    setIsFileUploading(false);
                    setIsTextPasteModalOpen(false);
                    // Redirect to the home page using the useHistory hook.
                    localStorage.clear();
                    // window.location.reload(true);
                  } else {
                    setShowError({
                      isOpen: true,
                      message: "File upload failed. Please try again.",
                    });
                    setIsFileUploading(false);
                    setIsTextPasteModalOpen(false);
                  }
                }
              }
            );
          } else {
            if (result.status == 402) {
              console.log("triggerd");
              setIsFileUploading(false);
              setIsTextPasteModalOpen(false);
              // toggleModal(); // Close the current modal (Settingmodal)
              setShowError({
                isOpen: true,
                message: result.data.message,
              });
              return;
            } else {
              setShowError({
                isOpen: true,
                message: "File upload failed. Please try again.",
              });
              setIsFileUploading(false);
              setIsTextPasteModalOpen(false);
            }
          }
        });
      };
    }
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputText(text);
    //  const blob = new Blob([text], { type: 'application/octet-stream' });
  };

  const uploadLink = async (link) => {
    setIsFileUploading(true);
    setIsLinkPasteModalOpen(false);
    try {
      const result = await authDataObject.UploadPasteLink(link);
      if (result?.status === 201 || result?.status === "201") {
        // setIsFileUploading(false);
        queryClient.invalidateQueries("file-list");
      } else {
        if (result.status == 402) {
          setIsFileUploading(false);
          toggleModal(); // Close the current modal (Settingmodal)
          setShowError({
            isOpen: true,
            message: result.data.message,
          });
          return;
        }
        if (result?.status == 403) {
          setShowError({
            isOpen: true,
            message: "Session expired",
          });
          setIsFileUploading(false);
          localStorage.clear();
        } else {
          setShowError({
            isOpen: true,
            message: "File upload failed. Please Check the link.",
          });
          setIsFileUploading(false);
        }
      }
    } catch (err) {
      console.log("this", err);
      setShowError({
        isOpen: true,
        message: err?.response?.data?.message,
      });
      setIsFileUploading(false);
      setIsLinkPasteModalOpen(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        // size="md"
        centered
        className="custom-modal"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ModalHeader toggle={toggleModal}>
          <FaTimes className="close-icon" onClick={toggleModal} />
        </ModalHeader>
        <ModalBody>
          <div
            className="content"
            style={{
              width: "100%",
            }}
          >
            <div className="downloadicon_container">
              <img
                src={Download_icon}
                alt="download_icon"
                className="download_icon"
              />
            </div>
            <div className="title">Select files to continue</div>
            <div className="subtitle">
              Unleash the Power of Insight
              <br />
              Upload Your Images, Text Files, or PDFs Now!
            </div>

            <div
              className="link-button"
              style={{
                backgroundColor: "#100be5",
                color: "#fff",
              }}
              onClick={() => {
                fileInputRef.current.click();
              }}
            >
              <input
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                accept=".pdf , .jpg , .jpeg , .png .webp" //pdf and images
              />{" "}
              Select files
            </div>
            <div
              className="link-button"
              onClick={() => {
                setInputLink("");
                setIsLinkPasteModalOpen(true);
                toggleModal();
              }}
            >
              Paste the file link
            </div>
            <div className="link-button" onClick={handlePasteLink}>
              Paste the text
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* internal modal */}
      {/* Paste text  */}
      {/* {isTextPasteModalOpen && ( */}
      <Modal
        isOpen={isTextPasteModalOpen}
        toggle={() => setIsTextPasteModalOpen(false)}
        // size="md"
        centered
        className="custom-modal"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ModalHeader toggle={() => setIsTextPasteModalOpen(false)}>
          <div className="paste-link-title-container">
            {" "}
            <img
              src={Backicon}
              className="close-icon"
              alt="images"
              onClick={() => {
                setIsTextPasteModalOpen(false);
                toggleModal();
              }}
            />{" "}
            <div className="pastelink-title">Paste text here ...</div>
          </div>
          {/* <FaTimes className="close-icon" onClick={() => setIsFileLinkModalOpen(false)} /> */}
        </ModalHeader>
        <ModalBody size="sm" style={{ padding: "0px" }}>
          <div className="content">
            <textarea
              id="myTextarea"
              rows="16"
              className="textarea-style"
              placeholder="Paste text"
              value={inputText}
              onChange={(e) => {
                handleInputChange(e);
              }}
              onPaste={(e) => {
                handleInputChange(e);
              }}
              style={{ height: "300px", overflowY: "scroll" }}
            ></textarea>{" "}
            <div className="action-button-container">
              <div>
                {" "}
                <button
                  className="action-button"
                  onClick={() => {
                    handlePasteText();
                  }}
                >
                  Submit
                </button>
              </div>
              <div>
                <button
                  className="action-button"
                  onClick={() => {
                    setIsTextPasteModalOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* )} */}
      {/* Paste Link  */}
      {/* {isLinkPasteModalOpen && ( */}

      <Modal
        isOpen={isLinkPasteModalOpen}
        toggle={() => setIsLinkPasteModalOpen(false)}
        size="md"
        centered
        className="custom-modal"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ModalHeader toggle={() => setIsLinkPasteModalOpen(false)}>
          <div className="paste-link-title-container">
            {" "}
            <img
              src={Backicon}
              className="close-icon"
              alt="images"
              onClick={() => {
                setIsLinkPasteModalOpen(false);
                toggleModal();
              }}
            />{" "}
            <div className="pastelink-title">Paste a link here ...</div>
          </div>
          {/* <FaTimes className="close-icon" onClick={() => setIsFileLinkModalOpen(false)} /> */}
        </ModalHeader>
        <ModalBody size="sm" style={{ padding: "0px" }}>
          <div className="content">
            <textarea
              id="myTextarea"
              rows="16"
              className="textarea-style"
              placeholder="Paste a link"
              value={inputLink}
              onChange={(e) => {
                setInputLink(e.target.value);
              }}
              onPaste={(e) => {
                setInputLink(e.target.value);
              }}
              style={{ height: "300px", overflowY: "scroll" }}
            ></textarea>{" "}
            <div className="action-button-container">
              <div>
                {" "}
                <button
                  className="action-button"
                  onClick={() => {
                    uploadLink(inputLink);
                  }}
                  type="button"
                >
                  Submit
                </button>
              </div>
              <div>
                <button
                  className="action-button"
                  onClick={() => {
                    setIsLinkPasteModalOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {showError && (
        <ErrorAlert
          isOpen={showError.isOpen}
          handleClose={() => setShowError(false)}
          message={showError.message}
        />
      )}
    </>
  );
};

export default Settingmodal;
